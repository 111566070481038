import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import {
  selectActiveOrg,
  selectOrgId,
  selectShowOrgWarning,
  selectShowTotpWarning,
  selectUserName,
  selectUserRole,
  selectUserTOTP,
} from '@/app/module/user/store/selectors'
import { selectCountryNames } from '@/app/module/utils/store/selectors'
import BillingComponent from './component'
import PaymentComponent from './component/payment'
import { PAYMENT_ROLES } from './roles'
import {
  autoRechargeCard,
  chargeCard,
  deleteCard,
  downloadInvoice,
  generateProformaInvoice,
  getBalance,
  getCards,
  getInvoices,
  saveCard,
  transferFunds,
} from './store/actions'
import { selectAutoRecharge, selectCards } from './store/selectors'

const billingSelector = ({ auth, user, payments, utils }) => ({
  error: {
    ...user.details.error,
  },
  loading: user.details.loading,
  hydrateProps: ['token', 'orgId', 'role'],
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  countries: selectCountryNames({ utils }),
  autoRecharge: selectAutoRecharge({ payments }),
  role: selectUserRole({ user }),
  user: {
    name: selectUserName({ user }),
    email: pathOr('', ['details', 'data', 'email'], user),
  },
  invoices: {
    loading: pathOr(false, ['invoices', 'loading'], payments),
    data: pathOr([], ['invoices', 'data'], payments),
    error: pathOr([], ['invoices', 'error'], payments),
  },
  payment: {
    initialized: pathOr(false, ['cards', 'initialized'], payments),
    loading:
      pathOr(false, ['funds', 'loading'], payments) ||
      pathOr(false, ['cards', 'loading'], payments) ||
      pathOr(false, ['autoRecharge', 'loading'], payments) ||
      pathOr(false, ['proformaInvoice', 'loading'], payments) ||
      !pathOr(false, ['cards', 'initialized'], payments),
    cards: selectCards({ payments }),
  },
  activeOrg: selectActiveOrg({ user }),
  organizations: pathOr([], ['details', 'data', 'orgs'], user),
  transferFundsLoading: pathOr(false, ['transferFunds', 'loading'], payments),
  showOrgWarning: selectShowOrgWarning({ user }),
  showTotpWarning: selectShowTotpWarning({ user }),
  totpEnabled: selectUserTOTP(user),
})

const billingDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, role }) =>
        () => {
          const promises = [dispatch(getInvoices({ token, orgId })), dispatch(getBalance({ token, orgId }))]

          if (PAYMENT_ROLES.includes(role)) {
            promises.push(dispatch(getCards({ token, orgId })))
          }

          return Promise.all(promises)
        },
      downloadInvoice,
      saveCard,
      chargeCard,
      deleteCard,
      autoRechargeCard,
      generateProformaInvoice,
      createNotification,
      transferFunds,
    },
    dispatch,
  )

export const Billing = connect(billingSelector, billingDispatcher)(BillingComponent)

const paymentSelector = ({ auth, user, payments, utils }) => ({
  error: {
    ...user.details.error,
  },
  hydrateProps: ['token', 'orgId'],
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  countries: selectCountryNames({ utils }),
  autoRecharge: selectAutoRecharge({ payments }),
  user: {
    name: selectUserName({ user }),
    email: pathOr('', ['details', 'data', 'email'], user),
  },
  initialized: pathOr(false, ['cards', 'initialized'], payments),
  loading:
    pathOr(false, ['funds', 'loading'], payments) ||
    pathOr(false, ['cards', 'loading'], payments) ||
    !pathOr(false, ['cards', 'initialized'], payments),
  cards: selectCards({ payments }),
})

const paymentDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () =>
          Promise.all([dispatch(getCards({ token, orgId })), dispatch(getBalance({ token, orgId }))]),
      saveCard,
      chargeCard,
      deleteCard,
      autoRechargeCard,
    },
    dispatch,
  )

// eslint-disable-next-line
export const Payment = connect(paymentSelector, paymentDispatcher)(PaymentComponent)
