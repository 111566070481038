import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { TextField, MenuItem, TextFieldProps } from '@mui/material'

import { Option } from '@/app/component/form/types'

type Props<T extends FieldValues> = {
  name: Path<T>
  options?: Option[]
  label?: string
  multiple?: boolean
} & Pick<TextFieldProps, 'disabled' | 'autoFocus' | 'required'>

export function RHFSelect<T extends FieldValues>({ name, options, label, multiple, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={field.value || ''}
          label={label}
          size="small"
          fullWidth
          select
          error={!!error}
          helperText={<>{error?.message}</>}
          SelectProps={{ multiple }}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
          {...props}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value} aria-label={option.label} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    ></Controller>
  )
}
