import { createAsyncState } from '@/app/service/util'

export default createAsyncState({
  files: {
    loading: false,
    loadingItems: [],
    error: {},
    data: {},
  },
})
