import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { Slider, FormControlLabel, SliderProps } from '@mui/material'

type Props<T extends FieldValues> = {
  name: Path<T>
  label?: string
  required?: boolean
} & Pick<SliderProps, 'disabled' | 'autoFocus'>

export function RHFSlider<T extends FieldValues>({ name, label, required, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          required={required}
          control={
            <Slider
              {...props}
              aria-label={name}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
              {...field}
              value={field.value || 0}
            />
          }
        />
      )}
    />
  )
}
