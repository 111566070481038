import React from 'react'
import { CountryTimezone, FilesType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { DRAGTYPE, SMS_ACTION_DEFAULT_SENDERID } from '@/app/module/campaigns/definitions'
import { ActionContext } from '@/app/module/campaigns/utils/actions/types'
import { getActionOptionsForCampaign } from '@/app/module/campaigns/utils/actions/options'
import {
  CampaignTemplateType,
  PartFamily,
  Language,
  LocalAction,
  PersonalizationType,
  ReplyType,
  SenderIdOption,
  SnippetType,
  LocalOnAnswerType,
  ChoiceValueMultiple,
} from '@/app/module/campaigns/types'
import ActionsBox from '@/app/module/campaigns/component/actions/actions-box'
import ChoiceTagMultiple from './choice-tag-multiple'
import { GetCampaigns, GetImageProps, GetSnippets, GetVoiceProps, SaveSnippet } from './part-config-props'

type Props = {
  actions: LocalAction[]
  activeSpeechLang: string
  campaignIds: SelectOption<number>[]
  countryCode: string
  countriesTimezones: CountryTimezone[]
  defaultLanguage: string
  files: FilesType
  hasTranscribe: boolean
  id: string
  index: number
  isCloseDisabled: boolean
  label: string
  languages: Language[]
  orgId: number
  personalizationList: PersonalizationType
  questionIndex: number
  replies: ReplyType[]
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  token: string
  timezone: string
  timezones: TimezoneWithCountryCodeType
  transcribeEnabled: boolean
  type: CampaignTemplateType

  getCampaigns: GetCampaigns
  getImageProps: GetImageProps
  getSnippets: GetSnippets
  getVoiceProps: GetVoiceProps
  onChange: (value: Partial<LocalOnAnswerType>, speechLang: string, index: number) => void
  onClose: (index: number) => void
  saveSnippet: SaveSnippet
}

const ResponseVoiceSpeechItem: React.FC<Props> = ({
  actions,
  activeSpeechLang,
  campaignIds,
  countriesTimezones,
  countryCode,
  defaultLanguage,
  files,
  hasTranscribe,
  id,
  index,
  isCloseDisabled,
  label,
  languages,
  orgId,
  personalizationList,
  questionIndex,
  replies,
  senderIds,
  snippets,
  timezone,
  timezones,
  token,
  transcribeEnabled,
  type,
  getCampaigns,
  getImageProps,
  getSnippets,
  getVoiceProps,
  onChange,
  onClose,
  saveSnippet,
}) => {
  const handleActionsChange = React.useCallback(
    (actionsList: LocalAction[]) => {
      onChange({ actions: actionsList }, activeSpeechLang, index)
    },
    [activeSpeechLang, index, onChange],
  )
  const handleChoiceChange = React.useCallback(
    (newValue: ChoiceValueMultiple) => onChange(newValue, activeSpeechLang, index),
    [activeSpeechLang, index, onChange],
  )
  const handleLabelChange = React.useCallback(
    (newLabel: string) => onChange({ label: newLabel }, activeSpeechLang, index),
    [activeSpeechLang, index, onChange],
  )
  const handleRemove = React.useCallback(() => onClose(index), [index, onClose])

  const actionOptions = React.useMemo(
    () =>
      getActionOptionsForCampaign({
        campaignType: type,
        partType: PartFamily.Voice,
        actionNamespace: ActionContext.OnAnswer,
      }),
    [type],
  )

  const value = React.useMemo(() => ({ ranges: [], replies, speech: true }), [replies])

  return (
    <ActionsBox
      actionOptions={actionOptions}
      actions={actions}
      campaignIds={campaignIds}
      countriesTimezones={countriesTimezones}
      countryCode={countryCode}
      defaultLanguage={defaultLanguage}
      defaultSenderId={SMS_ACTION_DEFAULT_SENDERID.voiceQuestion}
      dragContext={`${DRAGTYPE}-choice-${index}`}
      key={index}
      files={files}
      hasTranscribe={hasTranscribe}
      index={questionIndex}
      isCloseDisabled={isCloseDisabled}
      label={label}
      languages={languages}
      orgId={orgId}
      personalizationList={personalizationList}
      senderIds={senderIds}
      snippets={snippets}
      timezone={timezone}
      timezones={timezones}
      token={token}
      transcribeEnabled={transcribeEnabled}
      updateContactConfig={updateContactConfig}
      voiceId={`${id}-${index}`}
      whatsappSenderIds={[]}
      onChange={handleActionsChange}
      onLabelChange={handleLabelChange}
      getCampaigns={getCampaigns}
      getImageProps={getImageProps}
      getSnippets={getSnippets}
      getVoiceProps={getVoiceProps}
      onClose={handleRemove}
      saveSnippet={saveSnippet}
    >
      <ChoiceTagMultiple
        data-testid={`voice-speech-choice-input-${activeSpeechLang}-${index}`}
        value={value}
        onChange={handleChoiceChange}
      />
    </ActionsBox>
  )
}

const updateContactConfig = {
  invalid: false,
  openResponse: false,
  question: true,
}

export default React.memo(ResponseVoiceSpeechItem)
