import { createReducer } from '@/app/service/util'
import {
  ACTION_GET_BILLING_FAILURE,
  ACTION_GET_BILLING_REQUEST,
  ACTION_GET_BILLING_SUCCESS,
} from '@/app/module/dashboard/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  // BILLING
  [ACTION_GET_BILLING_REQUEST]: (state) => ({
    ...state,
    billing: {
      ...state.billing,
      loading: true,
    },
  }),
  [ACTION_GET_BILLING_SUCCESS]: (state, { value }) => ({
    ...state,
    billing: {
      ...state.billing,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_BILLING_FAILURE]: (state, { value }) => ({
    ...state,
    billing: {
      ...state.billing,
      loading: false,
      error: value,
    },
  }),
})
