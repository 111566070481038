import { Dialog, DialogContent, DialogTitle, Icon, IconButton, styled } from '@mui/material'
import React from 'react'
import { defaultNewHook } from '@/app/module/phonenumbers/definitions'
import Hook, { HookProps } from './hook'

type Props = Omit<HookProps, 'hook'> & { onClose: () => void }

const AddRuleDialog: React.FC<Props> = ({ onClose, ...props }) => (
  <StyledDialog open onClose={onClose}>
    <DialogTitle
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {getDialogTitle(props.type)}
      <IconButton onClick={onClose}>
        <Icon>close</Icon>
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ paddingBottom: 0 }}>
      <Content>
        <Hook hook={defaultNewHook} {...props} />
      </Content>
    </DialogContent>
  </StyledDialog>
)

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '1400px',
    maxWidth: '90vw',
  },
}))

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
}))

const getDialogTitle = (type: string) => {
  switch (type) {
    case 'sms':
      return 'Add Incoming SMS Rule'

    case 'whatsapp':
      return 'Add Incoming WhatsApp Rule'

    default:
      return 'Add Rule'
  }
}

export default AddRuleDialog
