import { concat, omit, pathOr, uniq, without } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_FILE_RESET,
  ACTION_GET_FILE_FAILURE,
  ACTION_GET_FILE_REQUEST,
  ACTION_GET_FILE_SUCCESS,
  ACTION_SAVE_FILE_FAILURE,
  ACTION_SAVE_FILE_REQUEST,
  ACTION_SAVE_FILE_SUCCESS,
} from '@/app/module/phonenumbers/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  // GET FILE
  [ACTION_GET_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_GET_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: {
          ...pathOr({}, [value.filename], state.files.data),
          ...value,
        },
      },
    },
  }),
  [ACTION_GET_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // SAVE FILE
  [ACTION_SAVE_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_SAVE_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: value,
      },
    },
  }),
  [ACTION_SAVE_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // RESET FILE(S)
  [ACTION_FILE_RESET]: (state, { value }) => ({
    ...state,
    files: {
      loading: false,
      loadingItems: [],
      data: value.id ? omit([value.id], state.files.data) : defaultState.files.data,
    },
  }),
})
