import { Button, Card, CardContent, Icon, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { ChannelType, InboundHook, PhoneNumber } from '@/app/module/phonenumbers/types'
import { useGetPhoneNumber } from '@/app/module/phonenumbers/hooks/use-get-phonenumber'
import { useCreateHook } from '@/app/module/phonenumbers/hooks/use-create-hook'
import { useUpdateHook } from '@/app/module/phonenumbers/hooks/use-update-hook'
import { omit } from 'ramda'
import Loading from '@/app/component/guard/loading'
import { FooterContainer, Root, TabDivider, TabTitle, TabToolbar } from './phone-number-tab-components'
import Hook, { HookProps } from './hook'
import DeleteHookDialog from './delete-hook-dialog'
import AddRuleDialog from './add-rule-dialog'

type Props = Omit<HookProps, 'expanded' | 'hook' | 'index' | 'phoneNumber' | 'type' | 'saveHook' | 'setDeleteId'> & {
  phoneNumberId: number
}

const SmsHooksTab: React.FC<Props> = ({ phoneNumberId, ...props }) => {
  const [createHook, { isLoading: isCreating }] = useCreateHook(ChannelType.Sms)
  const [updateHook, { isLoading: isUpdating }] = useUpdateHook(ChannelType.Sms)
  const { data, refetch } = useGetPhoneNumber(phoneNumberId)
  const phoneNumber = data as PhoneNumber | undefined
  const hooks = React.useMemo(() => {
    if (!phoneNumber || !phoneNumber.hooks) {
      return []
    }
    return [...phoneNumber.hooks].sort((a, b) => a.id - b.id)
  }, [phoneNumber]) as InboundHook[]

  const [expanded, setExpanded] = React.useState<number>(hooks.length ? hooks[0].id : 0)
  const [addRuleOpen, setAddRuleOpen] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState<number | undefined>()

  React.useEffect(
    () =>
      setExpanded((s) => {
        // if there are no hooks, return 0
        if (!hooks.length) {
          return 0
        }
        // if the current expanded hook is not in the list of hooks, return the first hook
        if (!s || !hooks.find((h) => h.id === s)) {
          return hooks[0].id
        }
        // otherwise return the current expanded hook
        return s
      }),
    [hooks],
  )

  const openAddRuleDialog = () => setAddRuleOpen(true)
  const closeAddRuleDialog = () => setAddRuleOpen(false)
  const closeDeleteDialog = () => setDeleteId(undefined)

  return (
    <Loading isLoading={isCreating || isUpdating}>
      <CardContent>
        <TabToolbar>
          <TabTitle variant="h6" color="textSecondary">
            Incoming SMS Rules
          </TabTitle>
          <div>
            <Tooltip id="reload-hooks" title="Reload Rules">
              <IconButton onClick={refetch}>
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </TabToolbar>
        <div
          style={{
            margin: 'auto',
          }}
        >
          {!!phoneNumber && (
            <Root>
              {!hooks.length && (
                <Card>
                  <CardContent>
                    <Typography component="div" color="textSecondary" variant="h6">
                      Add rules here to let us know what to do with incoming SMS. For example,
                      <ul
                        style={{
                          marginTop: '5px',
                        }}
                      >
                        <li>configure keywords for your contacts to text in to opt out of your campaigns</li>
                        <li>configure a webhook to be notified about every incoming SMS</li>
                        <li>configure which SMS should automatically be forwarded to you via SMS</li>
                      </ul>
                    </Typography>
                  </CardContent>
                </Card>
              )}
              {hooks.map((hook, i) => {
                const hookId = hook.id
                return (
                  <Hook
                    {...props}
                    expanded={expanded}
                    hook={hook}
                    index={i}
                    key={hookId}
                    phoneNumber={phoneNumber}
                    type={ChannelType.Sms}
                    onChange={setExpanded}
                    saveHook={(updatedHook) => updateHook(phoneNumberId, hookId, updatedHook)}
                    setDeleteId={setDeleteId}
                  />
                )
              })}
            </Root>
          )}
          <TabDivider />
          {addRuleOpen && !!phoneNumber && (
            <AddRuleDialog
              {...props}
              phoneNumber={phoneNumber}
              expanded={expanded}
              index={-1}
              type={ChannelType.Sms}
              onClose={closeAddRuleDialog}
              saveHook={async (newHook) => {
                const created = await createHook(phoneNumberId, omit(['id'], newHook))
                if (created) {
                  closeAddRuleDialog()
                  setExpanded(created)
                }
              }}
              setDeleteId={setDeleteId}
            />
          )}

          <FooterContainer>
            <Button variant="contained" color="primary" onClick={openAddRuleDialog}>
              Add a new Rule
            </Button>
          </FooterContainer>
        </div>
      </CardContent>

      {!!deleteId && (
        <DeleteHookDialog
          deleteId={deleteId}
          didId={phoneNumberId}
          type={ChannelType.Sms}
          onClose={closeDeleteDialog}
        />
      )}
    </Loading>
  )
}

export default SmsHooksTab
