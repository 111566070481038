import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material'

import { Option } from '@/app/component/form/types'

type Props<T extends FieldValues> = {
  name: Path<T>
  options?: Option[]
  label?: string
  autoFocus?: boolean
}

export function RHFCheckbox<T extends FieldValues>({ name, options, label, autoFocus }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <FormLabel>{label}</FormLabel>
          <FormGroup>
            {options?.map((option, i) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                autoFocus={autoFocus && i === 0}
                disabled={option.disabled}
                control={
                  <Checkbox
                    key={option.value}
                    checked={value.includes(option.value)}
                    disabled={option.disabled}
                    aria-label={option.label}
                    onChange={() => {
                      if (value.includes(option.value)) {
                        onChange((value as string[]).filter((item) => item !== option.value))
                      } else {
                        onChange([...value, option.value])
                      }
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    ></Controller>
  )
}
