import { z } from 'zod'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Flag from '@/app/component/atom/flag'
import { Form } from '@/app/component/form'
import { isPhone } from '@/app/component/form/utils/validation'
import { esCountries } from '@/config/resources'
import React from 'react'

export function OrganizationFormSkeleton() {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={38} sx={{ mb: 2, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width={150} height={38} sx={{ mb: 2, ml: 'auto', borderRadius: 1 }} />
    </>
  )
}

type CountryOption = {
  value: string
  label: string
  code: string
}

const countryOptions: CountryOption[] = esCountries.map(({ name, code }) => ({ value: name, label: name, code }))

const orgCreateSchema = z.object({
  name: z.string().min(1, { message: 'Required field' }).describe('Name'),
  phone: z.string().optional().describe('Phone number').transform(isPhone(false)),
  address: z.string().optional().describe('Address'),
  country: z.string().optional().describe('Country'),
  location: z.string().optional().describe('Location'),
  website: z.string().optional().describe('Website'),
})

type OrganizationData = z.infer<typeof orgCreateSchema>

type Props = {
  isLoading?: boolean
  submitLabel: string
  defaultValues?: OrganizationData
  onSubmit: (data: OrganizationData) => Promise<boolean>
}

const OrganizationForm: React.FC<Props> = ({ defaultValues, isLoading, submitLabel, onSubmit }) => {
  const handleSubmit = async (data: OrganizationData) => {
    const phoneNumber = data.phone ? data.phone.replace(/\+/g, '') : undefined
    const body = {
      name: data.name,
      address: data.address || '',
      country: data.country || '',
      location: data.location || '',
      website: data.website || '',
      phone: phoneNumber || '',
    }
    onSubmit(body)
  }

  return (
    <Form
      defaultValues={defaultValues}
      fields={{
        country: {
          type: 'autocomplete',
          fieldProps: {
            label: 'Country',
            options: countryOptions,
            name: 'country',
            renderOption: (itemProps, option) => {
              const { code, label } = option as CountryOption
              return (
                <Box component="li" {...itemProps} sx={{ display: 'flex', gap: '0.5rem' }}>
                  <Flag code={code} />
                  <span>{label}</span>
                </Box>
              )
            },
          },
        },
      }}
      schema={orgCreateSchema}
      shouldReset
      onSubmit={handleSubmit}
    >
      <CardActions sx={{ p: 0 }}>
        <Button type="submit" variant="contained" disabled={isLoading}>
          {submitLabel}
        </Button>
      </CardActions>
    </Form>
  )
}

export default OrganizationForm

export { orgCreateSchema }

export type { OrganizationData, Props as OrganizationFormProps }
