import { esApi as baseApi } from '@/__generated/es-api'

export const esApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Organization', 'OrgDid'],
  endpoints: {
    getOrgDid: {
      providesTags: ['OrgDid'],
    },
    getOrgDids: {
      providesTags: ['OrgDid'],
    },
    getOrganization: {
      providesTags: ['Organization'],
    },
    updateOrganization: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled

          dispatch(
            baseApi.util.updateQueryData('getOrganization', { orgId: arg.orgId }, (data) => ({
              ...data,
              ...arg.body,
            })),
          )
        } catch {
          // do nothing
        }
      },
    },
  },
})
