import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { find, map, propEq, reduce } from 'ramda'
import React from 'react'

type Props = {
  className?: string
  disabled?: boolean
  error?: boolean
  options: any
  grouped?: boolean
  formControlProps?: any
  helperText?: string
  showAll: boolean
  value: string | number
  labelId?: string
  id?: string
  label?: string
  hideRemoveSelection?: boolean
  freeSolo?: boolean
  clearOnBlur?: boolean
  filterOptions?: any
  getOptionLabel?: any

  onChange: (selected: string) => void
  keyPropFn: (option: any) => string
  valuePropFn: (option: any) => string
}

type GroupType = {
  title: string
  data: any[]
}

const prepOptions = ({ options, grouped, keyPropFn, valuePropFn }: Props) => {
  if (grouped) {
    return reduce(
      (accum: any, group: GroupType) => {
        const { title, data } = group
        const groupedOptions = map(
          (singleOption: any) => ({
            groupName: title,
            label: valuePropFn(singleOption),
            id: keyPropFn(singleOption),
          }),
          data,
        )
        return [...accum, ...groupedOptions]
      },
      [],
      options,
    )
  }
  return map(
    (singleOption: any) => ({
      label: valuePropFn(singleOption),
      id: keyPropFn(singleOption),
      disabled: singleOption.disabled,
    }),
    options,
  )
}

export default function SearchableSelect(props: Props) {
  const [selected, setSelected] = React.useState(props.value)
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    setSelected(props.value)
  }, [props.value])

  const preparedOptions = prepOptions(props)

  const onChange = (_: any, newSelected: any) => {
    if (!newSelected) {
      props.onChange('')
    } else if (typeof newSelected === 'string') {
      props.onChange(newSelected)
    } else if (newSelected.inputValue) {
      props.onChange(newSelected.inputValue)
    } else {
      if (newSelected) {
        setSelected(newSelected.id)
      }
      props.onChange(newSelected?.id || '')
      if (props.freeSolo) {
        setTimeout(() => {
          setSelected('')
        })
      } else if (newSelected) {
        setSelected(newSelected.id)
      }
    }
    setInputValue('')
  }

  const handleInputChange = (_: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    switch (reason) {
      case 'clear':
        setInputValue('')
        break
      case 'input':
      case 'reset':
        setInputValue(newInputValue)
        break
      default:
        break
    }
  }

  return (
    <Autocomplete
      className={props.className}
      data-testid={props.id || 'autocomplete-select'}
      disabled={props.disabled}
      id={props.id || 'autocomplete-select'}
      {...(props.grouped && {
        groupBy: (option: any) => option.groupName,
      })}
      disableClearable={props.hideRemoveSelection}
      clearOnBlur={props.clearOnBlur}
      inputValue={props.freeSolo ? inputValue : undefined}
      onChange={onChange}
      value={props.freeSolo ? '' : find(propEq('id', selected), preparedOptions) || ''}
      options={preparedOptions}
      getOptionLabel={props.getOptionLabel || ((option) => option.label || '')}
      renderInput={(params) => <TextField variant="standard" {...params} label={props.label || ''} />}
      freeSolo={props.freeSolo}
      filterOptions={props.filterOptions}
      handleHomeEndKeys
      selectOnFocus
      blurOnSelect={props.freeSolo}
      getOptionDisabled={(option) => option.disabled}
      onInputChange={props.freeSolo ? handleInputChange : undefined}
    />
  )
}
