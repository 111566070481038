import { RouteComponentProps } from 'react-router-dom'

export type Permissions = {
  creditCard: boolean
  downloadAuditLog: boolean
  downloadInvoice: boolean
  downloadPurchases: boolean
  downloadReport: boolean
  getBalance: boolean
  invites: boolean
  manageUsers: boolean
  plans: boolean
  updateBalance: boolean
  updateOrg: boolean
}

export type UserRole =
  | 'owner'
  | 'manager'
  | 'collaborator'
  | 'report_viewer'
  | 'project_report_viewer'
  | 'customer_service'

export type Organization = {
  address?: string
  balance: number
  balanceHuman: string
  balanceIsBelowNotificationAmount: boolean
  balanceNotification: number
  country?: string
  location?: string
  maxDailySpend: number
  name: string
  orgId: number
  phone?: string
  permissions: Permissions
  role: UserRole
  spendNotification: number
  totpAll: boolean
  website?: string
}

export type Balance = {
  autoRecharge: boolean
  autoRechargeAmount: number
  autoRechargeThreshold: number
  balance: number
  balanceHuman: string
  balanceMicro: number
}

export type SelectOption<T> = {
  label: string
  value: T
}

export type BackendFile = {
  contentType: string
  filename: string
  rowCount: number
  sha256: string
  size: number
}

export type CountryTimezone = SelectOption<string>

export type ChangeEvent<T = string> = {
  name: string
  value: T
}

export type TableHeader = {
  fields: string[]
  icon: string
  title: string
}

export type TablePagination = {
  namespace: string
  page: number
  pageSizeValues: SelectOption<number>[]
  size: number
  total: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

export type TableSpecItem = {
  display: string
  icon?: string
  type: string
  tz?: string
}

export type TableSpecs = Record<string, TableSpecItem>

export type TimezoneOption = {
  country: string
  tzs: Array<{
    city: string
    offset: string
    timezone: string
  }>
}

export type Timezones = Record<string, TimezoneOption>

export type FieldSpec = {
  editable?: boolean
  label?: string
  type:
    | 'audio'
    | 'autocomplete'
    | 'checkbox'
    | 'conditions'
    | 'copyBox'
    | 'date'
    | 'datetime'
    | 'hidden'
    | 'number'
    | 'radio'
    | 'password'
    | 'phone'
    | 'select'
    | 'selectMultiple'
    | 'text'
    | 'select'

  [key: string]: any
}

export type FormSpecs = Record<string, FieldSpec>

export type FormHeader = {
  fields: string[]
  title: string
}

export type MatchParams = {
  name: string
}

export type MatchProps = RouteComponentProps<MatchParams>['match']

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ConditionFunc =
  | 'and'
  | 'or'
  | '=='
  | '!='
  | '>'
  | '<'
  | '>='
  | '<='
  | 'in'
  | 'notIn'
  | 'contain'
  | 'not_contain'
  | 'empty'
  | 'not_empty'
  | 'startsWith'
  | 'notStartsWith'
  | 'anyStartsWith'
  | 'noneStartsWith'
  | 'before'
  | 'after'
  | 'mod2_equal'
  | 'mod3_equal'
  | 'mod4_equal'
  | 'mod5_equal'
  | ''

export type Condition = [ConditionFunc, ...(string | number | Condition)[]]

export type LoadingError = {
  status?: number
  message: string
}

export type FileType = {
  filename: string
  title: string
  category: string
  thumb?: string
  url: string
  transcription?: string
}

export type FilesType = Record<string, FileType>

export type FileUploadType = {
  file: File
  filename: string
  filter?: string
  type: string
}

export type SingleTimezoneType = {
  city: string
  offset: string
  timezone: string
}

export type TimezoneWithCountryCodeType = {
  [key: string]: {
    country: string
    tzs: SingleTimezoneType[]
  }
}

export type ReceiptLines = {
  line1?: string
  line2?: string
  line3?: string
  line4?: string
  line5?: string
}
