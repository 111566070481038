import { CreateOrganizationApiArg } from '@/__generated/es-api'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { ERROR_SAVE_ORGANIZATION_SERVER } from '@/app/module/user/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getUser } from '@/app/module/user/store/actions'

export const useCreateOrganization = () => {
  const dispatch = useAppDispatch()
  const [create, res] = esApi.endpoints.createOrganization.useMutation()
  const token = useSelector(selectToken)

  const createOrganization = useCallback(
    async (body: CreateOrganizationApiArg['body']) => {
      try {
        await create({
          body,
        }).unwrap()
        dispatch(
          createNotification({
            'add-organization': {
              type: 'success',
              message: `Created organization ${body.name} successfully.`,
            },
          }),
        )
        dispatch(getUser({ token }))
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'add-organization': {
              type: 'error',
              message: ERROR_SAVE_ORGANIZATION_SERVER,
            },
          }),
        )
        return false
      }
    },
    [token, dispatch, create],
  )

  return [createOrganization, res] as const
}
