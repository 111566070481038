import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCampaignSummary, getCampaigns } from '@/app/module/campaigns/store/actions'
import { selectCampaignSummary } from '@/app/module/campaigns/store/selectors'
import { selectContactsItemConfig } from '@/app/module/contacts/store/selectors'
import { getBalance } from '@/app/module/payments/store/actions'
import { selectAutoRecharge } from '@/app/module/payments/store/selectors'
import {
  selectActiveOrg,
  selectOrgId,
  selectShowOrgWarning,
  selectShowTotpWarning,
  selectUserId,
  selectUserTOTP,
} from '@/app/module/user/store/selectors'
import Dashboard from './component'
import { getBilling } from './store/actions'
import { selectStats } from './store/selectors'

const selector = ({ auth, user, demos, utils, campaigns, dashboard, plans, payments }) => ({
  error: pathOr([], ['list', 'error'], demos),
  demos: {
    isLoading: pathOr([], ['list', 'loading'], demos),
    demos: pathOr([], ['list', 'data', 'demos'], demos),
  },
  campaigns: {
    isLoading: pathOr([], ['list', 'loading'], campaigns),
    values: pathOr({}, ['list', 'data', 'values'], campaigns),
    ordered: pathOr([], ['list', 'data', 'pages', 1], campaigns),
  },
  summary: {
    isLoading: pathOr(true, ['itemSummary', 'loading'], campaigns),
    itemSummary: selectCampaignSummary({ campaigns }),
  },
  dashboard: {
    stats: selectStats(dashboard),
    plan: plans.item.data,
    isLoading: pathOr(false, ['item', 'loading'], plans) || pathOr(false, ['billing', 'loading'], dashboard),
  },
  autoRecharge: selectAutoRecharge({ payments }),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  userLoading: pathOr({}, ['details', 'loading'], user) || pathOr(false, ['autoRecharge', 'loading'], payments),
  profile: pathOr({}, ['details', 'data'], user),
  specs: selectContactsItemConfig({ contacts: [], user, customFields: [], utils }),
  hydrateProps: ['token', 'orgId'],
  activeOrg: selectActiveOrg({ user }),
  showOrgWarning: selectShowOrgWarning({ user }),
  showTotpWarning: selectShowTotpWarning({ user }),
  totpEnabled: selectUserTOTP(user),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () => {
          if (!token || !orgId) return Promise.resolve()

          return Promise.all([
            dispatch(getBilling({ token, orgId })),
            dispatch(getCampaigns({ token, orgId })),
            dispatch(getBalance({ token, orgId })),
          ])
        },
      getCampaignSummary,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Dashboard))
