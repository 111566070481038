import { Divider, styled, Toolbar, Typography } from '@mui/material'

export const TabToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  alignItems: 'space-between',
})

export const TabTitle = styled(Typography)({
  marginTop: '10px',
})

export const TabDivider = styled(Divider)({
  margin: '30px 0px',
})

export const FooterContainer = styled('div')({
  textAlign: 'center',
  width: '100%',
})

export const Root = styled('div')({
  width: '100%',
})
