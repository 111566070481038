import { usePostDidRegisterWhatsAppMutation } from '@/__generated/es-api'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'

export const useRegisterWhatsappSenderId = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [registerDid, response] = usePostDidRegisterWhatsAppMutation()

  /**
   * @param didId - eS DID ID
   * @param profileName - WhatsApp profile name
   * @param wabaId - WhatsApp Business Account ID
   */
  const registerWhatsappSenderId = useCallback(
    async (didId: number, wabaId: string, profileName?: string) => {
      try {
        await registerDid({
          orgId: orgId.toString(),
          didId: didId.toString(),
          body: {
            wabaId,
            profileName,
          },
        }).unwrap()
        invalidatePhoneNumbersCache(dispatch)
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'register-wa-did': {
              type: 'error',
              disableAutoHide: true,
              message: 'Failed to register WhatsApp Sender ID with Meta, please try again later.',
            },
          }),
        )
        return false
      }
    },
    [orgId, dispatch, registerDid],
  )

  return [registerWhatsappSenderId, response] as const
}
