import { Box, Button, Card, CardContent, Divider, Grid, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { styled } from '@mui/material/styles'
import CopyBox from '@/app/component/atom/copy-box'
import { Select } from '@/app/component/atom/form'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import ErrorLayout from '@/app/component/layout/error'
import PageTitle from '@/app/component/layout/page-title'
import Modal from '@/app/component/layout/modal'
import createHydrated from '@/app/component/wrapper/hydratable'
import { usePrevious } from '@/app/helpers'
import { FormHeader, FormSpecs, LoadingError, Permissions, SelectOption, UserRole } from '@/app/types'
import { CommonUserType, InvitePayload, InviteType, UserType } from '@/app/module/user/types'
import { SchemaOrg } from '@/__generated/es-api'
import { useGetOrganization } from '@/app/module/user/hooks/use-get-organization'
import AuditLog from './audit-log'
import SMSReportContainer from './sms-report.container'
import Users from './users'
import AddOrganization from './add-organization'
import UpdateOrganization from './update-organization'
import { OrganizationFormSkeleton } from './organization-form'

type OrganizationProps = {
  activeOrgId: number
  addNewSpecs: FormSpecs
  apiToken: string
  organization: SchemaOrg
  classes: Record<string, string>
  error?: LoadingError
  formattedInvitations: CommonUserType[]
  formattedUsers: CommonUserType[]
  headers: FormHeader[]
  invitations: {
    loading: boolean
    error: string
    data: InviteType[]
    init: boolean
  }
  history: History
  loading: boolean
  orgId: number
  permissions: Permissions
  role: UserRole
  selectedOrgId: number
  specs: FormSpecs
  tabName: string
  token: string
  userId: number
  userOrganizations: SelectOption<number>[]
  users: {
    loading: boolean
    error: string
    data: UserType[]
    init: boolean
  }
  deleteInvitation: (payload: { token: string; orgId: number; inviteId: number }) => Promise<void>
  deleteUser: (payload: { token: string; orgId: number; userId: number }) => Promise<void>
  downloadAuditLog: (params: { orgId: number; token: string }) => Promise<void>
  getInvitations: (payload: { token: string; orgId: number }) => Promise<void>
  getUsers: (payload: { token: string; orgId: number }) => Promise<void>
  hydrate: (params: { token: string; orgId: number }) => void
  inviteUser: (payload: { token: string; orgId: number; item: InvitePayload }) => Promise<void>
  selectOrg: (orgId: number) => void
}

export const TABS = {
  INFO: 'info',
  AUDIT: 'audit',
  USERS: 'users',
  REPORT: 'report',
}

const TABS_MAP = {
  [TABS.INFO]: {
    name: TABS.INFO,
    title: 'Info',
    Content: (props: OrganizationProps) => {
      const history = useHistory()
      const { isLoading, isFetching } = useGetOrganization(props.selectedOrgId)

      const orgValues = React.useMemo(
        () =>
          props.userOrganizations.map((option) => ({
            label: option.label,
            value: String(option.value),
          })),
        [props.userOrganizations],
      )

      const prevOrg = usePrevious(props.activeOrgId)
      React.useEffect(() => {
        if (prevOrg && prevOrg !== props.activeOrgId) {
          history.push('/account/organization')
        }
      }, [history, prevOrg, props.activeOrgId, props.history])

      const isSkeleton = isLoading || isFetching

      return (
        <div>
          <CardContent>
            <Grid container spacing={8}>
              <Grid item md={8} xs={12}>
                <Select
                  id="select-organization"
                  name="select-organization"
                  label="Select organization"
                  value={String(props.selectedOrgId)}
                  values={orgValues}
                  onChange={({ value }) => history.push(`/account/organization/info/${value}`)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <CopyBox style={{ float: 'right' }} text={props.selectedOrgId.toString()} title="Organization ID" />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid container spacing={8}>
            <Grid item md={8} xs={12}>
              {!isSkeleton && <UpdateOrganization orgId={props.selectedOrgId} />}
              {isSkeleton && (
                <Box p={2}>
                  <OrganizationFormSkeleton />
                </Box>
              )}
            </Grid>
          </Grid>
        </div>
      )
    },
  },
  [TABS.AUDIT]: {
    name: TABS.AUDIT,
    title: 'Audit Log',
    Content: (props: OrganizationProps) => <AuditLog {...props} />,
  },
  [TABS.USERS]: {
    name: TABS.USERS,
    title: 'Users',
    Content: (props: OrganizationProps) => <Users {...props} />,
  },
  [TABS.REPORT]: {
    name: TABS.REPORT,
    title: 'SMS Deliverability Report',
    Content: () => <SMSReportContainer />,
  },
}

const tabNames = Object.values(TABS)

function OrganizationComponent(props: OrganizationProps) {
  const history = useHistory()
  const [isAddNewOrgModalOpen, setAddNewOrgModalOpen] = React.useState(false)
  const { loading, error, tabName } = props
  const { Content } = TABS_MAP[tabName] || {}

  return (
    <Loading isLoading={loading} error={error}>
      <div
        style={{
          paddingBottom: '1px',
        }}
      >
        <ContentLayout>
          <Header>
            <PageTitle>Organization</PageTitle>
            <HeaderButtonContainer>
              <div>
                <Button variant="contained" color="primary" onClick={() => setAddNewOrgModalOpen(true)}>
                  Add new Organization
                </Button>
              </div>
            </HeaderButtonContainer>
          </Header>
          <Card style={{ marginBottom: '90px' }}>
            <Toolbar style={{ backgroundColor: '#f5f5f5', minHeight: '0' }}>
              <Tabs
                value={tabName}
                indicatorColor="primary"
                onChange={(e, tab) => {
                  history.push(`/account/organization/${tab}`)
                }}
              >
                {tabNames.map((name, index) => (
                  <Tab key={name} id={`org-tab-${index}`} value={name} label={TABS_MAP[name].title} />
                ))}
              </Tabs>
            </Toolbar>
            {tabNames.indexOf(tabName) > -1 && (
              <div id={`org-content-${tabName}`}>
                <Content {...props} />
              </div>
            )}
            {tabNames.indexOf(tabName) === -1 && (
              <div id="org-content-error">
                <ErrorLayout status={404}>
                  <Typography>Page not found</Typography>
                </ErrorLayout>
              </div>
            )}
          </Card>
        </ContentLayout>
      </div>

      <Modal isOpen={isAddNewOrgModalOpen} title="Add new Organization" onClose={() => setAddNewOrgModalOpen(false)}>
        <AddOrganization onClose={() => setAddNewOrgModalOpen(false)} />
      </Modal>
    </Loading>
  )
}

const Header = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
})

const HeaderButtonContainer = styled('div')({
  display: 'flex',
})

export default createHydrated(OrganizationComponent)
