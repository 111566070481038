import http from '@/config/http'
import { createAsyncActions } from '@/app/service/util'
import { getFilesItem, saveFile } from '@/app/module/files/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_FILE_RESET,
  ACTION_GET_FILE_FAILURE,
  // Files
  ACTION_GET_FILE_REQUEST,
  ACTION_GET_FILE_SUCCESS,
  ACTION_SAVE_FILE_FAILURE,
  ACTION_SAVE_FILE_REQUEST,
  ACTION_SAVE_FILE_SUCCESS,
} from '@/app/module/phonenumbers/definitions'

const {
  request: getFileRequest,
  success: getFileSuccess,
  failure: getFileFailure,
} = createAsyncActions({
  request: ACTION_GET_FILE_REQUEST,
  success: ACTION_GET_FILE_SUCCESS,
  failure: ACTION_GET_FILE_FAILURE,
})

const {
  request: saveFileRequest,
  success: saveFileSuccess,
  failure: saveFileFailure,
} = createAsyncActions({
  request: ACTION_SAVE_FILE_REQUEST,
  success: ACTION_SAVE_FILE_SUCCESS,
  failure: ACTION_SAVE_FILE_FAILURE,
})

export const onError =
  ({ message }) =>
  (dispatch) => {
    dispatch(
      createNotification({
        'save-hooks': {
          type: 'error',
          message,
        },
      }),
    )
  }

export const getPhonenumbersFile =
  ({ token, orgId, msgId, file }) =>
  (dispatch) => {
    dispatch(getFileRequest({ id: msgId, file }))

    return getFilesItem({ token, orgId, name: file })(dispatch)
      .then((res) => {
        if (res instanceof Error) {
          throw res
        }
        dispatch(
          getFileSuccess({
            id: msgId,
            filename: file,
            url: res.url,
          }),
        )
        return res
      })
      .catch((err) => {
        getFileFailure({
          id: msgId,
          status: err.status,
          message: err.message,
        })
        return err
      })
  }

export const savePhonenumbersFile =
  ({ token, orgId, msgId, item }) =>
  (dispatch) => {
    dispatch(saveFileRequest({ id: msgId }))

    return saveFile({
      token,
      orgId,
      item: {
        ...item,
      },
    })(dispatch)
      .then((res = {}) => {
        if (res instanceof Error) {
          throw res
        }
        const dlPath = http.files.get.getSignablePath({ orgId, name: res.filename })
        const data = {
          paths: [dlPath],
        }
        return Promise.all([res, dlPath, request.auth.sign({ token, item: data })])
      })
      .then(([{ filename, title }, signedPath, signedRes]) => {
        const result = {
          id: msgId,
          filename,
          title,
          url: http.files.get.getPath({
            signedPath,
            urlToken: signedRes[signedPath],
          }),
        }

        dispatch(saveFileSuccess(result))
        return result
      })
      .catch((err) => {
        dispatch(
          saveFileFailure({
            id: msgId,
            status: err.status,
            message: err.message,
          }),
        )
        return err
      })
  }

export const clearFiles = (id) => ({
  type: ACTION_FILE_RESET,
  value: { id },
})
