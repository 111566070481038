import { FieldValues, Path, useFormContext } from 'react-hook-form'

import { TextField, TextFieldProps } from '@mui/material'

type Props<T extends FieldValues> = {
  name: Path<T>
} & Pick<
  TextFieldProps,
  'label' | 'type' | 'required' | 'disabled' | 'placeholder' | 'multiline' | 'rows' | 'autoFocus'
>

export function RHFTextField<T extends FieldValues>({ name, ...props }: Props<T>) {
  const { register, formState } = useFormContext<T>()
  const error = formState.errors[name]

  return (
    <TextField
      size="small"
      fullWidth
      sx={{ mb: 2 }}
      InputLabelProps={{ shrink: true }}
      {...props}
      inputProps={{
        ...register(name, {
          valueAsNumber: props.type === 'number',
          setValueAs: (value) => value || undefined,
        }),
        'aria-label': name,
      }}
      error={!!error}
      helperText={<>{error?.message}</>}
    />
  )
}
