export const ACTION_GET_CARDS_REQUEST = '@actions/GET_CARDS_REQUEST'
export const ACTION_GET_CARDS_SUCCESS = '@actions/GET_CARDS_SUCCESS'
export const ACTION_GET_CARDS_FAILURE = '@actions/GET_CARDS_FAILURE'

export const ACTION_SAVE_CARD_REQUEST = '@actions/SAVE_CARD_REQUEST'
export const ACTION_SAVE_CARD_SUCCESS = '@actions/SAVE_CARD_SUCCESS'
export const ACTION_SAVE_CARD_FAILURE = '@actions/SAVE_CARD_FAILURE'

export const ACTION_CHARGE_CARD_REQUEST = '@actions/CHARGE_CARD_REQUEST'
export const ACTION_CHARGE_CARD_SUCCESS = '@actions/CHARGE_CARD_SUCCESS'
export const ACTION_CHARGE_CARD_FAILURE = '@actions/CHARGE_CARD_FAILURE'

export const ACTION_DELETE_CARD_REQUEST = '@actions/DELETE_CARD_REQUEST'
export const ACTION_DELETE_CARD_SUCCESS = '@actions/DELETE_CARD_SUCCESS'
export const ACTION_DELETE_CARD_FAILURE = '@actions/DELETE_CARD_FAILURE'

export const ACTION_GET_INVOICES_REQUEST = '@actions/GET_INVOICES_REQUEST'
export const ACTION_GET_INVOICES_SUCCESS = '@actions/GET_INVOICES_SUCCESS'
export const ACTION_GET_INVOICES_FAILURE = '@actions/GET_INVOICES_FAILURE'

export const ACTION_DOWNLOAD_INVOICE_REQUEST = '@actions/DOWNLOAD_INVOICE_REQUEST'
export const ACTION_DOWNLOAD_INVOICE_SUCCESS = '@actions/DOWNLOAD_INVOICE_SUCCESS'
export const ACTION_DOWNLOAD_INVOICE_FAILURE = '@actions/DOWNLOAD_INVOICE_FAILURE'

export const ACTION_AUTO_RECHARGE_CARD_REQUEST = '@actions/AUTO_RECHARGE_CARD_REQUEST'
export const ACTION_AUTO_RECHARGE_CARD_SUCCESS = '@actions/AUTO_RECHARGE_CARD_SUCCESS'
export const ACTION_AUTO_RECHARGE_CARD_FAILURE = '@actions/AUTO_RECHARGE_CARD_FAILURE'

export const ACTION_GET_BALANCE_REQUEST = '@actions/GET_BALANCE_REQUEST'
export const ACTION_GET_BALANCE_SUCCESS = '@actions/GET_BALANCE_SUCCESS'
export const ACTION_GET_BALANCE_FAILURE = '@actions/GET_BALANCE_FAILURE'

export const ACTION_GENERATE_PROFORMA_INVOICE_REQUEST = '@actions/GENERATE_PROFORMA_INVOICE_REQUEST'
export const ACTION_GENERATE_PROFORMA_INVOICE_SUCCESS = '@actions/GENERATE_PROFORMA_INVOICE_SUCCESS'
export const ACTION_GENERATE_PROFORMA_INVOICE_FAILURE = '@actions/GENERATE_PROFORMA_INVOICE_FAILURE'

export const ACTION_TRANSFER_FUNDS_REQUEST = '@actions/TRANSFER_FUNDS_REQUEST'
export const ACTION_TRANSFER_FUNDS_SUCCESS = '@actions/TRANSFER_FUNDS_SUCCESS'
export const ACTION_TRANSFER_FUNDS_FAILURE = '@actions/TRANSFER_FUNDS_FAILURE'

export const ERROR_GET_CARDS_SERVER = 'Failed to get credit cards. Please try again later.'
export const ERROR_SAVE_CARD_SERVER = 'Unable to process your card details. Please try again later.'
export const ERROR_CHARGE_CARD_SERVER = 'Failed to charge credit card. Please try again later.'
export const ERROR_CHARGE_UNDER_REVIEW =
  'You currently have a payment under review. Please wait for it to be approved or contact support.'
export const ERROR_DELETE_CARD_SERVER = 'Failed to delete credit card. Please try again later.'
export const ERROR_AUTO_RECHARGE_CARD_SERVER = 'Failed to set auto recharging. Please try again later.'
export const ERROR_GET_BALANCE_SERVER = 'Failed to get the updated balance.'

export const ERROR_GET_RECEIPTS_SERVER = 'Failed to get receipts. Please try again later.'

export const ERROR_GET_INVOICES_SERVER = 'Failed to get invoices. Please try again later.'

export const ERROR_DOWNLOAD_INVOICE_SERVER = 'Failed to download invoice. Please try again later.'

export const ERROR_GENERATE_PROFORMA_INVOICE = 'Failed to download proforma invoice. Please try again later.'

export const ERROR_TRANSFER_FUNDS = 'Failed to transfer funds. Please try again later.'
export const SUCCESS_TRANSFER_FUNDS = 'Funds transferred successfully.'

export const SUCCESS_SAVE_CARD_SERVER = 'Card successfully added to your account.'
export const SUCCESS_CHARGE_CARD_SERVER = 'You successfully added %AMOUNT%USD to your account.'
export const UNDER_REVIEW_CHARGE_CARD_SERVER =
  "Your charge requires manual review. You'll be notified once it is approved. Contact support with any questions."
export const SUCCESS_DELETE_CARD_SERVER = 'Your card was successfully deleted.'

export const MIN_THRESHOLD = 10
export const MAX_THRESHOLD = 2000

export const MIN_AMOUNT = 20
export const MAX_AMOUNT = 2000
