import React from 'react'
import CardContent from '@mui/material/CardContent'
import { useCreateOrganization } from '@/app/module/user/hooks/use-create-organization'
import OrganizationForm, { OrganizationData } from './organization-form'

type Props = {
  onClose: () => void
}

const AddOrganization: React.FC<Props> = ({ onClose }) => {
  const [createOrganization, { isLoading }] = useCreateOrganization()

  const handleSubmit = async (body: OrganizationData) => {
    const success = await createOrganization(body)
    if (success) {
      onClose()
    }
    return success
  }

  return (
    <CardContent>
      <OrganizationForm isLoading={isLoading} submitLabel="Create organization" onSubmit={handleSubmit} />
    </CardContent>
  )
}

export default AddOrganization

export type { Props as AddOrganizationProps }
