import { noop } from '@/app/definitions'
import config from '@/config'
import { useCallback, useEffect, useState } from 'react'
import { FbLoginOptions, FbStatusResponse } from '@/config/globals'
import { sdkUrl } from './facebook-definitions'
import { toPhoneNumber } from './phone'

const scriptId = 'facebook-js-sdk'

const { appId, apiVersion: version, configureId, solutionId } = config.meta

// src: https://developers.facebook.com/docs/javascript/quickstart
export const loadFacebookSdk = (timeout = 10000): Promise<boolean> => {
  if (!appId || !version || checkIfFacebookSdkIsLoaded()) {
    return Promise.resolve(false)
  }

  return new Promise((resolve, reject) => {
    // create the script tag to actually load the SDK
    const script = document.createElement('script')
    script.id = scriptId
    script.setAttribute('data-testid', 'facebook-js-sdk')
    script.src = sdkUrl
    script.async = true
    script.defer = true
    script.crossOrigin = 'anonymous'
    document.body.appendChild(script)

    // set a timeout to check if the SDK was loaded in time
    const timeoutId = setTimeout(() => {
      window.fbAsyncInit = noop
      document.body.removeChild(script)
      reject(new Error('Timeout Error: Loading Facebook SDK'))
    }, timeout)

    window.fbAsyncInit = () => {
      clearTimeout(timeoutId)
      window.FB.init({
        appId,
        version,
        xfbml: true,
      })
      resolve(true)
    }
  })
}

export const fbLogin = (options: FbLoginOptions): Promise<FbStatusResponse> =>
  new Promise((resolve, reject) => {
    window.FB.login((response) => {
      switch (response.status) {
        case 'connected':
          return resolve(response)
        case 'authorization_expired':
          return reject(new Error('Authorization expired'))
        case 'not_authorized':
          return reject(new Error('Not authorized'))
        case 'unknown':
          return reject(new Error('Unknown error'))
        default:
          return reject(new Error('Not supported'))
      }
    }, options)
  })

export const useFacebookSdk = (timeout = 10000) => {
  const [failed, setFailed] = useState(false)
  const [loaded, setLoaded] = useState(checkIfFacebookSdkIsLoaded())

  useEffect(() => {
    if (loaded) {
      return
    }

    const load = async () => {
      try {
        const success = await loadFacebookSdk(timeout)
        if (!success) {
          setFailed(true)
          return
        }
        setFailed(false)
      } catch (error) {
        setFailed(true)
      } finally {
        setLoaded(true)
      }
    }

    load()
  }, [loaded, timeout])

  // Following this https://www.twilio.com/docs/whatsapp/tech-provider-program/integration-guide#adding-the-facebook-javascript-sdk - the 3rd step
  const launchEmbeddedSignup = useCallback((whatsAppMetaOtp: boolean, phoneNumber?: string) => {
    const baseOptions: FbLoginOptions = {
      config_id: configureId,
      auth_type: 'rerequest',
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        sessionInfoVersion: 3, // Required to get WABA ID
        setup: {
          solutionID: solutionId,
        },
      },
    }

    if (whatsAppMetaOtp) {
      const extras = baseOptions.extras as any
      const phone = phoneNumber ? toPhoneNumber(phoneNumber) : undefined
      return fbLogin({
        ...baseOptions,
        extras: {
          ...extras,
          setup: {
            ...extras?.setup,
            business: {
              phone: phone
                ? {
                    code: `+${phone.countryCallingCode}`,
                    number: phone.nationalNumber,
                  }
                : undefined,
            },
          },
        },
      })
    }

    return fbLogin({
      ...baseOptions,
      extras: {
        ...baseOptions.extras,
        featureType: 'only_waba_sharing',
      },
    })
  }, [])

  return { failed, loaded, launchEmbeddedSignup }
}

export const checkIfFacebookSdkIsLoaded = () => {
  if (window.FB || document.getElementById(scriptId)) {
    return true
  }
  return false
}
