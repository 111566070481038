import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { FormControl, FormLabel, Switch, SwitchProps, FormHelperText } from '@mui/material'

type Props<T extends FieldValues> = {
  name: Path<T>
  label?: string
  required?: boolean
} & Pick<SwitchProps, 'disabled' | 'autoFocus'>

export function RHFSwitch<T extends FieldValues>({ name, label, required, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error}>
          <div style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Switch {...field} {...props} inputProps={{ 'aria-label': name }} checked={field.value || false} />
            <FormLabel required={required}>{label}</FormLabel>
          </div>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  )
}
