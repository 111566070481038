import { numberWithCommas } from '@/app/service/util/format'
import { usageLevel } from '@/app/module/dashboard/store/selectors'
import React from 'react'
import { OrgPlan } from '@/app/module/plans/types'
import numbersRow from './numbersRow'
import PlanBlock from './planBlock'
import DashboardList from './dashboard-list'

type Props = {
  isLoading: boolean
  plan?: OrgPlan
  stats: {
    start: string
    end: string
    smsSent: number
    smsReceived: number
    voiceSent: number
    voiceReceived: number
    whatsappSent: number
    whatsappReceived: number
  }
}

const Usage: React.FC<Props> = ({ isLoading, plan, stats }) => {
  const items = React.useMemo(() => {
    if (!plan) {
      return []
    }

    return [
      {
        label: 'Stored contacts',
        value: numbersRow(
          usageLevel(plan.storedContactsNow.toString(), plan.storedContactsMax),
          `${numberWithCommas(plan.storedContactsNow)} / ${numberWithCommas(plan.storedContactsMax)}`,
        ),
      },
      {
        label: 'User accounts',
        value: `${plan.usersNow} / ${plan.usersIncluded}`,
      },
      {
        label: 'SMS sent / received',
        value: `${stats.smsSent} / ${stats.smsReceived}`,
      },
      {
        label: 'Calls sent / received',
        value: `${stats.voiceSent} / ${stats.voiceReceived}`,
      },
      {
        label: 'WhatsApp messages sent / received',
        value: `${stats.whatsappSent} / ${stats.whatsappReceived}`,
      },
    ]
  }, [plan, stats])

  return (
    <PlanBlock action={null} isLoading={isLoading} title={`Usage (${stats.start} to ${stats.end})`}>
      <DashboardList items={items} />
    </PlanBlock>
  )
}

export default Usage
