import React from 'react'
import Alert from '@mui/material/Alert'
import CardContent from '@mui/material/CardContent'
import { useUpdateOrganization } from '@/app/module/user/hooks/use-update-organization'
import { useGetOrganization } from '@/app/module/user/hooks/use-get-organization'
import { styled } from '@mui/material'
import OrganizationForm, { OrganizationData } from './organization-form'

type Props = {
  orgId: number
}

const UpdateOrganization: React.FC<Props> = ({ orgId }) => {
  const { data, error, isFetching } = useGetOrganization(orgId)
  const [updateOrganization, { isLoading }] = useUpdateOrganization()

  const handleSubmit = (body: OrganizationData) => updateOrganization(orgId, body)

  if (error) {
    return <Alert severity="error">Something went wrong, we couldn't get the organization details!</Alert>
  }

  if (!data || isFetching) {
    return null
  }

  return (
    <StyledCardContent>
      <OrganizationForm
        isLoading={isLoading}
        defaultValues={data}
        submitLabel="Update organization"
        onSubmit={handleSubmit}
      />
    </StyledCardContent>
  )
}

const StyledCardContent = styled(CardContent)({
  minHeight: '420px',
  paddingRight: 0,
})

export default UpdateOrganization

export type { Props as UpdateOrganizationProps }
