import { UpdateOrganizationApiArg } from '@/__generated/es-api'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { ERROR_SAVE_ORGANIZATION_SERVER } from '@/app/module/user/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getUser } from '@/app/module/user/store/actions'

export const useUpdateOrganization = () => {
  const dispatch = useAppDispatch()
  const [update, res] = esApi.endpoints.updateOrganization.useMutation()
  const token = useSelector(selectToken)

  const updateOrganization = useCallback(
    async (orgId: number, body: UpdateOrganizationApiArg['body']) => {
      try {
        await update({
          orgId: orgId.toString(),
          body,
        }).unwrap()
        dispatch(
          createNotification({
            'save-organization': {
              type: 'success',
              message: `Updated organization ${body.name} successfully.`,
            },
          }),
        )
        dispatch(getUser({ token }))
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'save-organization': {
              type: 'error',
              message: ERROR_SAVE_ORGANIZATION_SERVER,
            },
          }),
        )
        return false
      }
    },
    [token, dispatch, update],
  )

  return [updateOrganization, res] as const
}
