import InputField from '@/app/component/atom/form/input'
import { trimContent } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import { WA_BUTTON_TITLE_LENGTH } from '@/app/module/campaigns/definitions'
import validate, { hasMaxError, hasRequiredError } from '@/app/service/validate'
import { ChangeEvent } from '@/app/types'
import { styled } from '@mui/material'
import React from 'react'

type Props = {
  buttonIndex: number
  disabled?: boolean
  title: string
  onChange: (title: string) => void
}

const validators = {
  title: validate(hasRequiredError, hasMaxError(WA_BUTTON_TITLE_LENGTH)),
}

const WhatsappButtonChoice: React.FC<Props> = ({ buttonIndex, disabled, title, onChange }) => {
  const [error, setError] = React.useState<Record<string, string>>({})

  const handleBlur = React.useCallback(
    ({ value }: ChangeEvent) => {
      const trimmed = trimContent(value)

      setError((s) => ({
        ...s,
        title: validators.title(trimmed),
      }))

      if (trimmed !== title) {
        onChange(trimmed)
      }
    },
    [title, onChange],
  )
  const handleChange = React.useCallback(({ value }: ChangeEvent) => onChange(value), [onChange])

  React.useEffect(() => {
    if (!title) {
      return
    }
    setError((s) => ({
      ...s,
      title: '',
    }))
  }, [title])

  return (
    <Container>
      <Input
        data-testid={`wa-buttons-title-${buttonIndex}`}
        editable={!disabled}
        error={error.title}
        label="Text"
        name={`title-${buttonIndex}`}
        value={title || ''}
        variant="standard"
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Container>
  )
}

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 5,
}))

const Input = styled(InputField)(() => ({
  height: 'auto',
  maxHeight: 'none',
  minHeight: '42px',
  whiteSpace: 'unset',
  marginRight: 5,
  flex: 1,
}))

export default WhatsappButtonChoice

export type { Props as WhatsappButtonChoiceProps }
