import { CardContent, Icon, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useGetPhoneNumber } from '@/app/module/phonenumbers/hooks/use-get-phonenumber'
import { ChannelType, InboundHook, PhoneNumber } from '@/app/module/phonenumbers/types'
import { useCreateHook } from '@/app/module/phonenumbers/hooks/use-create-hook'
import { useUpdateHook } from '@/app/module/phonenumbers/hooks/use-update-hook'
import { defaultNewHook } from '@/app/module/phonenumbers/definitions'
import Loading from '@/app/component/guard/loading'
import Hook, { HookProps } from './hook'
import { Root, TabTitle, TabToolbar } from './phone-number-tab-components'
import DeleteHookDialog from './delete-hook-dialog'

type Props = Omit<HookProps, 'expanded' | 'hook' | 'index' | 'phoneNumber' | 'type' | 'saveHook' | 'setDeleteId'> & {
  phoneNumberId: number
}

const VoiceHookTab: React.FC<Props> = ({ phoneNumberId, ...props }) => {
  const [createHook, { isLoading: isCreating }] = useCreateHook(ChannelType.Voice)
  const [updateHook, { isLoading: isUpdating }] = useUpdateHook(ChannelType.Voice)
  const { data, refetch } = useGetPhoneNumber(phoneNumberId)
  const phoneNumber = data as PhoneNumber | undefined
  const callHook = React.useMemo(() => {
    if (!phoneNumber || !phoneNumber.callHooks?.length) {
      return undefined
    }
    return phoneNumber.callHooks[0]
  }, [phoneNumber]) as InboundHook

  const [expanded, setExpanded] = React.useState<number>(0)
  const [deleteId, setDeleteId] = React.useState<number | undefined>()

  React.useEffect(() => setExpanded(callHook ? callHook.id : 0), [callHook])

  const closeDeleteDialog = () => setDeleteId(undefined)

  return (
    <Loading isLoading={isCreating || isUpdating}>
      <CardContent>
        <TabToolbar>
          <TabTitle variant="h6" color="textSecondary">
            Incoming Voice Rules
          </TabTitle>
          <div>
            <Tooltip id="reload-hooks" title="Reload Rules">
              <IconButton onClick={refetch}>
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </TabToolbar>
        <div
          style={{
            margin: 'auto',
          }}
        >
          {!!phoneNumber && (
            <Root>
              <Hook
                {...props}
                expanded={expanded}
                files={{
                  data: phoneNumber.files,
                  loadingItems: [],
                }}
                hook={callHook || defaultNewHook}
                index={0}
                phoneNumber={phoneNumber}
                type={ChannelType.Voice}
                onChange={setExpanded}
                setDeleteId={setDeleteId}
                saveHook={async (newHook) => {
                  if (callHook) {
                    await updateHook(phoneNumberId, callHook.id, newHook)
                  } else {
                    await createHook(phoneNumberId, newHook)
                  }
                }}
              />
            </Root>
          )}
        </div>
      </CardContent>

      {!!deleteId && (
        <DeleteHookDialog
          deleteId={deleteId}
          didId={phoneNumberId}
          type={ChannelType.Voice}
          onClose={closeDeleteDialog}
        />
      )}
    </Loading>
  )
}

export default VoiceHookTab
