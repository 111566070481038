import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import { Option } from '@/app/component/form/types'

type Props<T extends FieldValues> = {
  name: Path<T>
  options?: Option[]
  label?: string
  autoFocus?: boolean
}

export function RHFRadioGroup<T extends FieldValues>({ name, options, label, autoFocus }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...field} error={!!error}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup>
            {options?.map((option, i) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                aria-label={option.label}
                control={<Radio disabled={option.disabled} checked={field.value === option.value} />}
                label={option.label}
                autoFocus={autoFocus && i === 0}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    ></Controller>
  )
}
