import { Grid } from '@mui/material'
import React from 'react'
import Form from '@/app/component/layout/form'
import { FormHeader, FormSpecs } from '@/app/types'
import { SchemaOrg } from '@/__generated/es-api'

type Props = {
  headers: FormHeader[]
  loading: boolean
  organization: SchemaOrg
  specs: FormSpecs
  validation: Record<string, (value: string) => string | undefined>

  onSubmit: (payload: { item: SchemaOrg }) => void
}

const SpendNotifications: React.FC<Props> = ({ headers, loading, organization, specs, validation, onSubmit }) => (
  <Grid container>
    <Grid item xs={12}>
      <Form
        id="edit-notifications"
        config={specs}
        headers={headers}
        loading={loading}
        submitLabel="Save"
        values={organization}
        validation={validation}
        onSubmit={({ item }: { item: SchemaOrg }) => {
          const newItem = {
            ...item,
            balanceNotification: Number(item.balanceNotification),
            maxDailySpend: Number(item.maxDailySpend),
            spendNotification: Number(item.spendNotification),
          }

          onSubmit({ item: newItem })
        }}
      />
    </Grid>
  </Grid>
)

export default SpendNotifications
