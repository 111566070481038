import parsePhoneNumberFromString from 'libphonenumber-js'
import { z, RefinementCtx } from 'zod'

export const isPhone =
  (isRequired: boolean) =>
  (arg: string | undefined, ctx: RefinementCtx): string => {
    if (!arg) {
      if (!isRequired) return ''

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Phone number is required',
      })
      return z.NEVER
    }

    const phone = parsePhoneNumberFromString(arg, {
      extract: false,
    })

    if (phone && phone.isValid()) {
      return phone.number
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number',
    })
    return z.NEVER
  }
