import { ContentAudio } from '@/app/module/campaigns/types'
import { Button, Icon, Menu, MenuItem, styled } from '@mui/material'
import React from 'react'
import VoiceProcessingDialog from './voice-processing-dialog'
import { GetVoiceProps } from './part-config-props'

type Props = {
  defaultLanguage: string
  id: string
  processingReply?: ContentAudio

  getVoiceProps: GetVoiceProps
  onChange: (value?: ContentAudio) => void
}

const VoiceProcessing: React.FC<Props> = ({ defaultLanguage, id, processingReply, onChange, getVoiceProps }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const openDialog = React.useCallback(() => setDialogOpen(true), [])
  const closeDialog = React.useCallback(() => setDialogOpen(false), [])

  const openMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget),
    [],
  )
  const closeMenu = React.useCallback(() => setMenuAnchorEl(null), [])

  const handleChange = React.useCallback(
    (value?: ContentAudio) => {
      onChange(value)
      closeDialog()
      closeMenu()
    },
    [closeDialog, closeMenu, onChange],
  )
  const handleDelete = React.useCallback(() => handleChange(undefined), [handleChange])

  return (
    <Container>
      {!processingReply && (
        <Button
          startIcon={<Icon>queue_music</Icon>}
          variant={processingReply ? 'contained' : 'outlined'}
          onClick={openDialog}
        >
          Add waiting message
        </Button>
      )}
      {!!processingReply && (
        <>
          <Button
            startIcon={<Icon>queue_music</Icon>}
            endIcon={<Icon>arrow_drop_down</Icon>}
            variant={processingReply ? 'contained' : 'outlined'}
            onClick={openMenu}
          >
            Edit waiting message
          </Button>
          <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={closeMenu}>
            <StyledMenuItem sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={openDialog}>
              <Icon>{processingReply ? 'edit' : 'add'}</Icon>
              {processingReply ? 'Edit' : 'Add'}
            </StyledMenuItem>
            <StyledMenuItem disabled={!processingReply} onClick={handleDelete}>
              <Icon>delete</Icon>
              Delete
            </StyledMenuItem>
          </Menu>
        </>
      )}

      {dialogOpen && (
        <VoiceProcessingDialog
          defaultLanguage={defaultLanguage}
          id={id}
          open
          processingReply={processingReply}
          getVoiceProps={getVoiceProps}
          onClose={closeDialog}
          onSubmit={handleChange}
        />
      )}
    </Container>
  )
}

const Container = styled('div')({
  marginBottom: 8,
})

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

export default VoiceProcessing
