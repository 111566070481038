import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import React from 'react'
import WhatsappIcon from '@mui/icons-material/WhatsApp'
import WhatsappMessage from '@/app/component/atom/whatsapp'
import IconText from '@/app/component/layout/icon-text'
import CustomDialog from '@/app/component/wrapper/custom-dialog'
import { ImageProps, LocalSendWhatsAppAction, PersonalizationType, VoiceProps } from '@/app/module/campaigns/types'
import { FileUploadType } from '@/app/types'

type Props = {
  defaultLanguage: string
  imageProps: Pick<ImageProps, 'getFile' | 'loading' | 'saveFileHandler'>
  personalizationList: PersonalizationType
  value: LocalSendWhatsAppAction
  voiceProps: Pick<
    VoiceProps,
    | 'defaultUpload'
    | 'files'
    | 'loading'
    | 'playId'
    | 'getFile'
    | 'onMicAccessError'
    | 'saveFileHandler'
    | 'setDefaultUpload'
    | 'setPlaying'
  >

  onClose: () => void
  onSave: (value: Partial<LocalSendWhatsAppAction>) => void
}

const CampaignContentQuestionDialogWhatsapp: React.FC<Props> = (props) => {
  const {
    text,
    image: initialImage,
    audio: initialAudio,
    video: initialVideo,
    document: initialDocument,
  } = React.useMemo(() => unwrapValue(props.value), [props.value])
  const [message, setMessage] = React.useState(text)
  const [image, setImage] = React.useState(initialImage)
  const [video, setVideo] = React.useState(initialVideo)
  const [audio, setAudio] = React.useState(initialAudio)
  const [document, setDocument] = React.useState(initialDocument)

  const { onSave, onClose, imageProps, voiceProps } = props

  const { loading } = imageProps

  const saveHandler = (file: FileUploadType) => {
    const { saveFileHandler } = imageProps
    return saveFileHandler(file).then((res) => {
      if (!(res instanceof Error)) {
        if (file.type === 'image') {
          setImage(res.filename)
        } else if (file.type === 'video') {
          setVideo(res.filename)
        } else if (file.type === 'document') {
          setDocument(res.filename)
        }
      }
    })
  }

  const saveAudioHandler = (file: FileUploadType) => {
    const { saveFileHandler } = voiceProps
    return saveFileHandler(file).then((res) => {
      if (!(res instanceof Error)) {
        setAudio(res.filename)
      }
      return res
    })
  }

  const removeMedia = () => {
    setAudio('')
    setDocument('')
    setImage('')
    setVideo('')
  }

  const item = React.useMemo(
    () => ({
      message: {},
    }),
    [],
  )

  return (
    <CustomDialog maxWidth="md" fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconText>
            <WhatsappIcon />
            <Typography variant="h6">Send WhatsApp message to contact</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '24px' }}>
        <div
          style={{
            width: '70vw',
            minWidth: '300px',
            maxWidth: '100%',
          }}
        >
          <WhatsappMessage
            {...props}
            {...voiceProps}
            {...imageProps}
            audio={audio}
            autoFocus
            canRegisterTemplate={false}
            document={document}
            enableMediaCaption
            image={image}
            info=""
            item={item}
            label="message"
            text={message}
            video={video}
            changeHandler={({ text: updatedText }) => {
              setMessage(updatedText || '')
            }}
            removeMedia={removeMedia}
            saveHandler={saveHandler}
            saveFileHandler={saveAudioHandler}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={(!message && !image && !audio && !video && !document) || loading}
          onClick={() =>
            onSave({
              sendWhatsapp: {
                language: 'en',
                ...(message && {
                  text: message,
                }),
                image,
                audio,
                video,
                document,
                translations: {},
                useCampaignSenderId: 'senderIdWhatsapp',
              },
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}

const unwrapValue = (value: LocalSendWhatsAppAction) =>
  pathOr(
    {
      audio: '',
      document: '',
      image: '',
      senderId: '0',
      text: '',
      useCampaignSenderId: '',
      video: '',
    },
    ['sendWhatsapp'],
    value,
  )

export default CampaignContentQuestionDialogWhatsapp
