import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { find, propEq } from 'ramda'
import React from 'react'
import SearchableSelect from '@/app/component/atom/form/searchable-select'
import Loading from '@/app/component/guard/loading'
import { styled } from '@mui/material'
import { SelectOption } from '@/app/types'
import { CampaignQuery } from '@/app/module/campaigns/types'
import { useUpatePhoneNumber } from '@/app/module/phonenumbers/hooks/use-update-phonenumber'
import { useGetPhoneNumber } from '@/app/module/phonenumbers/hooks/use-get-phonenumber'
import { TabTitle } from './phone-number-tab-components'

type Props = {
  campaignIds: SelectOption<number>[]
  orgId: number
  phoneNumberId: number
  token: string
  getCampaigns: (payload: { orgId: number; token: string; query: CampaignQuery }) => Promise<void>
}

const CampaignHookTab: React.FC<Props> = ({ campaignIds, orgId, phoneNumberId, token, getCampaigns }) => {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<SelectOption<number>[]>([])
  const { data: phoneNumber } = useGetPhoneNumber(phoneNumberId)
  const [updatePhoneNumber, { isLoading }] = useUpatePhoneNumber()

  React.useEffect(() => {
    if (token && orgId) {
      setLoading(true)
      getCampaigns({
        token,
        orgId,
        query: {
          templates: 'ivr',
          page: 1,
          size: 100,
          aborted: 'false',
          archived: 'false',
        },
      }).then(() => setLoading(false))
    }
  }, [orgId, token, getCampaigns])

  const campaignId = phoneNumber?.inboundCallCampId
  const campaignData = phoneNumber?.inboundCallCamp

  React.useEffect(() => {
    if (campaignId && campaignData && campaignIds.length > 0 && !find(propEq('value', campaignId), campaignIds)) {
      setData([
        {
          label: `${campaignData.name} - ${campaignId}`,
          value: campaignId,
        },
        ...campaignIds,
      ])
    } else {
      setData(campaignIds)
    }
  }, [campaignIds, campaignData, campaignId])

  return (
    <Loading isLoading={loading || isLoading}>
      <CardContent>
        <TabTitle variant="h6">Incoming Voice Campaign</TabTitle>
        <Typography variant="caption">
          Would you like us to pick up if someone is calling this number? If yes, choose a running and open Voice
          Campaign.
        </Typography>
        <div
          style={{
            margin: 'auto',
          }}
        >
          {!loading && (
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
              }}
            >
              <div
                style={{
                  width: '400px',
                }}
              >
                <SearchableSelect
                  formControlProps={{
                    margin: 'none',
                  }}
                  helperText="Only the 100 most recent campaigns are shown."
                  id="phonenumbers-select-campaign"
                  label="Select Campaign"
                  labelId="select-campaign-label"
                  options={data}
                  showAll
                  value={campaignId || 0}
                  keyPropFn={(option) => option.value}
                  valuePropFn={(option) => option.label}
                  onChange={(selectedId) => {
                    updatePhoneNumber(phoneNumberId, {
                      inboundCallCampId: Number(selectedId) || 0,
                    })
                  }}
                />
              </div>
              <Tooltip id="reload-campaigns" title="Reload Campaign List">
                <ReloadButton
                  onClick={() => {
                    if (token && orgId) {
                      setLoading(true)
                      getCampaigns({
                        token,
                        orgId,
                        query: {
                          templates: 'ivr',
                          page: 1,
                          size: 100,
                          aborted: 'false',
                          archived: 'false',
                        },
                      }).then(() => setLoading(false))
                    }
                  }}
                >
                  <Icon>refresh</Icon>
                </ReloadButton>
              </Tooltip>
            </div>
          )}
        </div>
      </CardContent>
    </Loading>
  )
}

export const ReloadButton = styled(IconButton)({
  marginTop: 15,
  height: 40,
})

export default CampaignHookTab
