import { createAsyncActions } from '@/app/service/util'
import request from '@/app/module/request'
import {
  ACTION_GET_BILLING_FAILURE,
  ACTION_GET_BILLING_REQUEST,
  ACTION_GET_BILLING_SUCCESS,
  ERROR_GET_BILLING_SERVER,
} from '@/app/module/dashboard/definitions'

const {
  request: getBillingRequest,
  success: getBillingSuccess,
  failure: getBillingFailure,
} = createAsyncActions({
  request: ACTION_GET_BILLING_REQUEST,
  success: ACTION_GET_BILLING_SUCCESS,
  failure: ACTION_GET_BILLING_FAILURE,
})

export { getBillingFailure, getBillingRequest, getBillingSuccess }

export const getBilling =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getBillingRequest())
    return request.billing
      .get({
        token,
        orgId,
        query: {},
      })
      .then((res) => {
        dispatch(getBillingSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getBillingFailure({
            status: err.status,
            message: ERROR_GET_BILLING_SERVER,
          }),
        )
        return err
      })
  }
