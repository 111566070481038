import { useAppDispatch } from '@/app/store/redux-hooks'
import { esApi } from '@/app/store/api'
import { cannot } from '@/app/helpers'
import { useCallback, useMemo } from 'react'
import { SchemaInboundHookPayload } from '@/__generated/es-api'
import { ChannelType, InboundHook } from '@/app/module/phonenumbers/types'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_ADD_PHONENUMBER_HOOK_SERVER } from '@/app/module/phonenumbers/definitions'
import { invalidatePhoneNumbersCache } from '@/app/module/phonenumbers/store/utils'
import { useSelector } from 'react-redux'
import { selectOrgId } from '@/app/module/user/store/selectors'

export const useCreateHook = (type: ChannelType.Sms | ChannelType.Voice | ChannelType.WhatsApp) => {
  const dispatch = useAppDispatch()
  const [createSMSHook, createSMSHookRes] = esApi.endpoints.createOrgDidHook.useMutation()
  const [createVoiceHook, createVoiceHookRes] = esApi.endpoints.createOrgDidCallHook.useMutation()
  const [createWhatsAppHook, createWhatsAppHookRes] = esApi.endpoints.createOrgDidWaHook.useMutation()
  const orgId = useSelector(selectOrgId)

  const method = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return createSMSHook
      case ChannelType.Voice:
        return createVoiceHook
      case ChannelType.WhatsApp:
        return createWhatsAppHook
      default:
        return cannot(type)
    }
  }, [type, createSMSHook, createVoiceHook, createWhatsAppHook])

  const res = useMemo(() => {
    switch (type) {
      case ChannelType.Sms:
        return createSMSHookRes
      case ChannelType.Voice:
        return createVoiceHookRes
      case ChannelType.WhatsApp:
        return createWhatsAppHookRes
      default:
        return cannot(type)
    }
  }, [type, createSMSHookRes, createVoiceHookRes, createWhatsAppHookRes])

  const createHook = useCallback(
    async (didId: number, payload: Omit<InboundHook, 'id'>) => {
      try {
        const { id } = await method({
          orgId: orgId.toString(),
          didId: didId.toString(),
          schemaInboundHookPayload: payload as SchemaInboundHookPayload,
        }).unwrap()
        dispatch(
          createNotification({
            'add-hook': {
              type: 'success',
              message: 'Rule added successfully.',
            },
          }),
        )
        invalidatePhoneNumbersCache(dispatch)
        return id
      } catch {
        dispatch(
          createNotification({
            'create-hook': {
              type: 'error',
              message: ERROR_ADD_PHONENUMBER_HOOK_SERVER,
            },
          }),
        )
        return undefined
      }
    },
    [orgId, dispatch, method],
  )

  return [createHook, res] as const
}
