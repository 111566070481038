import { Icon } from '@mui/material'
import React from 'react'

type Props = {
  hideNotSupported?: boolean
  supported: boolean
}

const SupportedIcon: React.FC<Props> = ({ hideNotSupported, supported }) => {
  if (hideNotSupported && !supported) {
    return null
  }

  return <Icon color={supported ? 'success' : 'error'}>{supported ? 'check_box' : 'cancel'}</Icon>
}

export default SupportedIcon
