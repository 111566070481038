import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCampaigns } from '@/app/module/campaigns/store/actions'
import { selectCampaignIds, selectSMSSenderIdValues } from '@/app/module/campaigns/store/selectors'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { getSnippets, saveSnippet } from '@/app/module/user/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectCountriesTimezones, selectDefaultCountryCode } from '@/app/module/utils/store/selectors'
import { MIC_ACCESS_ERROR } from '@/app/definitions'
import { createNotification } from '@/app/module/notifications/store/actions'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import PhoneNumbers from './component'
import Item from './component/item'
import { getPhonenumbersFile, onError, savePhonenumbersFile } from './store/actions'

export default PhoneNumbers

const itemSelector = ({ auth, user, phoneNumbers, customFields, logs, campaigns, utils }) => ({
  hydrateProps: ['token', 'orgId', 'phoneNumberId'],
  token: selectToken({ auth }),
  error: pathOr({}, ['item', 'error'], phoneNumbers),
  orgId: selectOrgId({ user }),
  personalizationList: selectPersonalizationList({ customFields }),
  userId: selectUserId({ user }),
  senderIds: selectSMSSenderIdValues({ logs }),
  campaignIds: selectCampaignIds({ campaigns }),
  files: {
    loadingItems: pathOr([], ['files', 'loadingItems'], phoneNumbers),
    data: pathOr({}, ['files', 'data'], phoneNumbers),
  },
  timezone: selectUserTimezone({ user, utils }),
  timezones: pathOr({}, ['countriesTimezonesList', 'data'], utils),
  countriesTimezones: selectCountriesTimezones({ utils }),
  countryCode: selectDefaultCountryCode({ utils }),
  snippets: pathOr({}, ['snippets'], user),
})

const itemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId }) =>
        () =>
          Promise.all([
            dispatch(getCustomFields({ token, orgId })),
            dispatch(
              getCampaigns({
                token,
                orgId,
                query: {
                  templates: 'ivr',
                  page: 1,
                  size: 100,
                },
                aborted: 'false',
                archived: 'false',
              }),
            ),
          ]),
      getCampaigns,
      getFile: getPhonenumbersFile,
      getSnippets,
      onError,
      onMicAccessError: () =>
        dispatch(
          createNotification({
            'mic-access': {
              type: 'error',
              message: MIC_ACCESS_ERROR,
            },
          }),
        ),
      saveFile: savePhonenumbersFile,
      saveSnippet,
    },
    dispatch,
  )

export const PhoneNumberItem = connect(itemSelector, itemDispatcher)(withRouter(Item))
