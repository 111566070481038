import { useEffect } from 'react'
import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_GET_ORGANIZATION_SERVER } from '@/app/module/user/definitions'

export const useGetOrganization = (orgId: number) => {
  const dispatch = useAppDispatch()
  const res = esApi.endpoints.getOrganization.useQuery(
    {
      orgId: orgId.toString(),
    },
    {
      skip: !orgId,
    },
  )

  useEffect(() => {
    if (!res.error) {
      return
    }

    dispatch(
      createNotification({
        'get-organization': {
          type: 'error',
          message: ERROR_GET_ORGANIZATION_SERVER,
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
