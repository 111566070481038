import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import TablePagination from '@/app/component/atom/table-pagination'
import Loading from '@/app/component/guard/loading'
import { formatDate } from '@/app/service/util'
import { useGetReceipts } from '@/app/module/payments/hooks/use-get-receipts'
import { SchemaPurchase } from '@/__generated/es-api'
import DownloadReceiptDialog from './download-receipt-dialog'

type Props = {
  orgId: number
}

const Receipts: React.FC<Props> = ({ orgId }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [toBeDownloaded, setToBeDownloaded] = React.useState<SchemaPurchase | undefined>()

  const { data, isFetching } = useGetReceipts()

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
  }

  const downloadReceipt = (receipt: SchemaPurchase) => setToBeDownloaded(receipt)

  const receipts = data || []

  return (
    <Loading style={{ paddingTop: '5px' }} isLoading={isFetching}>
      <TableContainer component="div">
        <Table size="small" className="receipts-table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Method</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.created && formatDate(row.created)}</TableCell>
                <TableCell>{row.transCode && paymentMethodMap[row.transCode]}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Download this Receipt as PDF">
                    <span>
                      <Button size="small" onClick={() => downloadReceipt(row)} variant="contained" color="primary">
                        Download Receipt
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        namespace="Receipts"
        page={page + 1}
        size={rowsPerPage}
        total={receipts.length}
        pageSizeValues={pageSizeValues}
        onPageSizeChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
      {toBeDownloaded && (
        <DownloadReceiptDialog orgId={orgId} receipt={toBeDownloaded} onClose={() => setToBeDownloaded(undefined)} />
      )}
    </Loading>
  )
}

const pageSizeValues = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

const paymentMethodMap: Record<string, string> = {
  purchase_of_funds_via_credit_card: 'Credit Card',
  purchase_of_funds_via_paypal: 'PayPal',
  purchase_of_funds_via_bank_wire: 'Bank Wire',
}

export default Receipts
