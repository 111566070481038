import { z } from 'zod'

const EnvIdSchema = z.enum(['test', 'mock', 'development', 'staging', 'production', 'preview'])

const WSS_URL = {
  test: 'ws://localhost:8081',
  mock: 'ws://localhost:8051',
  development: 'ws://localhost:9090',
  staging: 'wss://app-staging.engagespark.net/api',
  production: 'wss://app.engagespark.com/api',
  preview: 'wss://app-staging.engagespark.net/api',
}

const WEBRTC_URL = {
  test: 'ws://localhost:8081/org',
  mock: 'ws://localhost:8051/org',
  development: 'ws://localhost:9090/org',
  staging: 'wss://webrtc-staging.engagespark.net/org',
  production: 'wss://webrtc.engagespark.com/org',
  preview: 'wss://webrtc-staging.engagespark.net/org',
}

const WEBRTC_USERNAME = {
  test: 'webrtc',
  mock: 'webrtc',
  development: 'webrtc',
  staging: 'webrtc',
  production: 'webrtc',
  preview: 'webrtc',
}

const WEBRTC_PASSWORD = {
  test: 'noh4chaNahs6oMah',
  mock: 'noh4chaNahs6oMah',
  development: 'noh4chaNahs6oMah',
  staging: 'noh4chaNahs6oMah',
  production: 'noh4chaNahs6oMah',
  preview: 'noh4chaNahs6oMah',
}

const API_SVC_URL = {
  test: 'http://localhost:8081',
  mock: 'http://localhost:8051',
  development: 'http://localhost:9090',
  staging: 'https://app-staging.engagespark.net/api',
  production: 'https://app.engagespark.com/api',
  preview: 'https://app-staging.engagespark.net/api',
}

const STRIPE_KEY = {
  test: 'stripe_key',
  mock: 'stripe_key',
  development: 'pk_test_K0OKRArInoq18UDBFllXUCLQ',
  staging: 'pk_test_K0OKRArInoq18UDBFllXUCLQ',
  production: 'pk_live_g6wXYo9du5PrgBkE3ZfyP60g',
  preview: 'pk_test_K0OKRArInoq18UDBFllXUCLQ',
}

const RECAPTCHA_KEY = {
  test: 'recaptcha_key',
  mock: '6LfYxZgUAAAAAHNMNkAyVekutqgIxBVxQj13pIwc',
  development: '6LfYxZgUAAAAAHNMNkAyVekutqgIxBVxQj13pIwc',
  staging: '6LfYxZgUAAAAAHNMNkAyVekutqgIxBVxQj13pIwc',
  production: '6LeL-5kUAAAAAGm0dyhHZwI6kQxX-B7N4tWLcRKG',
  preview: '6LfYxZgUAAAAAHNMNkAyVekutqgIxBVxQj13pIwc',
}

const SENTRY_DSN = {
  test: undefined,
  mock: undefined,
  development: undefined,
  staging: 'https://1bcafe6dceaa373d321110187e306f42@o475520.ingest.us.sentry.io/4507289292963840',
  production: 'https://1bcafe6dceaa373d321110187e306f42@o475520.ingest.us.sentry.io/4507289292963840',
  preview: undefined,
}

const INTERCOM_APP_ID = {
  test: undefined,
  mock: 'vozsddrb',
  development: 'vozsddrb',
  staging: 'vozsddrb',
  production: 'ramtw5yo',
  preview: 'vozsddrb',
}

const META_API_VERSION = 'v21.0'

const META_APP_ID = {
  test: undefined,
  mock: undefined,
  development: undefined,
  staging: '602872698863295',
  production: '602872698863295',
  preview: undefined,
}

const META_CONFIGURE_ID = {
  test: undefined,
  mock: undefined,
  development: undefined,
  staging: '2580102845517694',
  production: '2580102845517694',
  preview: undefined,
}

const TWILIO_META_SOLUTION_ID = {
  test: undefined,
  mock: undefined,
  development: undefined,
  staging: '9138680292860701',
  production: '9138680292860701',
  preview: undefined,
}

export const defaultEnv: { app: z.infer<typeof EnvIdSchema>; integration: z.infer<typeof EnvIdSchema> } = {
  // inner wiring, the way this repo is bundled
  app: 'development',
  // outer wiring, connection to api endpoints and usage of api keys
  integration: 'staging',
}

const getEnvVar = (key: string) => {
  try {
    const env = EnvIdSchema.parse(import.meta.env[`VITE_${key}`])
    return env
  } catch (err) {
    if (key in window) {
      const env = EnvIdSchema.parse(window[key as keyof typeof window])
      return env
    }
    return undefined
  }
}

export default {
  env: {
    get app() {
      return getEnvVar('APP_ENV') || defaultEnv.app
    },
    get integration() {
      return getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
    },
  },
  ws: {
    get url() {
      const appEnv = getEnvVar('APP_ENV') || getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
      return WSS_URL[appEnv]
    },
  },
  webRTC: {
    get url() {
      const appEnv = getEnvVar('APP_ENV') || getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
      return WEBRTC_URL[appEnv]
    },
    get username() {
      const appEnv = getEnvVar('APP_ENV') || getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
      return WEBRTC_USERNAME[appEnv]
    },
    get password() {
      const appEnv = getEnvVar('APP_ENV') || getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
      return WEBRTC_PASSWORD[appEnv]
    },
  },
  svc: {
    get url() {
      const appEnv = getEnvVar('APP_ENV') || getEnvVar('INTEGRATION_ENV') || defaultEnv.integration
      return API_SVC_URL[appEnv]
    },
  },
  stripe: {
    get key() {
      return STRIPE_KEY[getEnvVar('INTEGRATION_ENV') || defaultEnv.integration]
    },
  },
  recaptcha: {
    get key() {
      return RECAPTCHA_KEY[getEnvVar('INTEGRATION_ENV') || defaultEnv.integration]
    },
  },
  mp3convert: {
    url: '/static/vmsg.wasm',
  },
  vendor: {
    css: [
      {
        name: 'font-main',
        type: 'font',
        family: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
        style: 'normal',
        url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro',
      },
      {
        name: 'material-icons',
        url: 'https://fonts.googleapis.com/icon?family=Material+Icons',
      },
    ],
    js: [
      {
        name: 'stripe',
        url: 'https://js.stripe.com/v3/',
      },
    ],
  },
  modules: {
    contacts: {
      pageSize: {
        default: 100,
        values: [25, 50, 100],
      },
    },
    cati: {
      pageSize: {
        default: 100,
        values: [25, 50, 100],
      },
    },
    exports: {
      pageSize: {
        default: 25,
      },
    },
    messages: {
      pageSize: {
        default: 100,
        values: [25, 50, 100],
      },
    },
    conversations: {
      pageSize: {
        default: 100,
      },
    },
    campaigns: {
      pageSize: {
        default: 100,
        values: [25, 50, 100],
      },
    },
    groups: {
      type: {
        type: 'NORMAL',
        name: 'groups',
        label: 'Groups',
        icon: 'format_list_bulleted',
      },
      pageSize: {
        default: 30,
      },
    },
    segments: {
      type: {
        type: 'SMART',
        name: 'segments',
        label: 'Segments',
        icon: 'transform',
      },
      pageSize: {
        default: 30,
      },
    },
    notifications: {
      pollInterval: 1000,
    },
  },
  display: {
    format: {
      date: 'YYYY MMM DD',
      dateTime: 'YYYY MMM DD HH:mm:ss',
      orgReportFilter: 'YYYY-MM-DD HH:mm',
    },
  },
  sentry: {
    get dsn() {
      const appEnv = getEnvVar('APP_ENV') || defaultEnv.app
      return SENTRY_DSN[appEnv]
    },
  },
  intercom: {
    get appId() {
      const appEnv = getEnvVar('APP_ENV') || defaultEnv.app
      return INTERCOM_APP_ID[appEnv]
    },
  },
  meta: {
    get appId() {
      const appEnv = getEnvVar('APP_ENV') || defaultEnv.app
      return META_APP_ID[appEnv]
    },
    apiVersion: META_API_VERSION,
    get configureId() {
      const appEnv = getEnvVar('APP_ENV') || defaultEnv.app
      return META_CONFIGURE_ID[appEnv]
    },
    get solutionId() {
      const appEnv = getEnvVar('APP_ENV') || defaultEnv.app
      return TWILIO_META_SOLUTION_ID[appEnv]
    },
  },
}
