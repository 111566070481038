import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js'

export const toPhoneNumber = (pn: string): PhoneNumber | undefined => {
  try {
    const parsed = parsePhoneNumber(pn)
    return parsed
  } catch {
    return undefined
  }
}
