import React from 'react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'

import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'

type Props<T extends FieldValues> = {
  name: Path<T>
  defaultCountry?: React.ComponentProps<typeof MuiTelInput>['defaultCountry']
} & Pick<MuiTelInputProps, 'label' | 'autoFocus' | 'required'>

export function RHFPhoneField<T extends FieldValues>({ name, defaultCountry, ...props }: Props<T>) {
  const { control } = useFormContext<T>()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          fullWidth
          size="small"
          defaultCountry={defaultCountry || 'US'}
          sx={{ mb: 2 }}
          {...props}
          error={!!error}
          inputProps={{
            'aria-label': name,
          }}
          helperText={error?.message}
        />
      )}
    />
  )
}
