import { InboundHook } from './types'

export const operands = [
  {
    label: 'equals',
    value: '==',
    keywords: [],
  },
  {
    label: 'does not equal',
    value: '!=',
    keywords: [],
  },
]

export const ACTION_GET_FILE_REQUEST = '@actions/PHONENUMBERS/GET_FILE_REQUEST'
export const ACTION_GET_FILE_SUCCESS = '@actions/PHONENUMBERS/GET_FILE_SUCCESS'
export const ACTION_GET_FILE_FAILURE = '@actions/PHONENUMBERS/GET_FILE_FAILURE'

export const ACTION_SAVE_FILE_REQUEST = '@actions/PHONENUMBERS/SAVE_FILE_REQUEST'
export const ACTION_SAVE_FILE_SUCCESS = '@actions/PHONENUMBERS/SAVE_FILE_SUCCESS'
export const ACTION_SAVE_FILE_FAILURE = '@actions/PHONENUMBERS/SAVE_FILE_FAILURE'

export const ACTION_FILE_RESET = '@actions/PHONENUMBERS/RESET_FILE'

// ERRORS
export const ERROR_GET_PHONENUMBERS_SERVER = 'Failed to get phone numbers. Please try again later.'
export const ERROR_GET_PHONENUMBER_SERVER = 'Failed to get phone number rules. Please try again later.'
export const ERROR_UPDATE_PHONENUMBER_SERVER = 'Failed to update phone number. Please try again later.'
export const ERROR_UPDATE_PHONENUMBER_HOOK_SERVER = 'Failed to update phone number rule. Please try again later.'
export const ERROR_ADD_PHONENUMBER_HOOK_SERVER = 'Failed to add phone number rule. Please try again later.'
export const ERROR_DELETE_PHONENUMBER_HOOK_SERVER = 'Failed to delete phone number rule. Please try again later.'

export const defaultNewHook: InboundHook = {
  conditions: {
    unexpected: false,
    conditions: null,
  },
  actions: [],
  id: 0,
  label: '',
}
