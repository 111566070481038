import Typography from '@mui/material/Typography'
import { formatPhoneNumber, parsePhoneNumber } from '@/app/service/util'
import React from 'react'

type Props = {
  phoneNumber?: string
}

const Phonenumber: React.FC<Props> = ({ phoneNumber = '' }) => {
  const { country, phone } = parsePhoneNumber(phoneNumber)

  const phoneNumberValue = !country ? phone : formatPhoneNumber(`+${phone}`)

  return (
    <Typography>
      <span>{phoneNumberValue}</span>
    </Typography>
  )
}

export default Phonenumber
