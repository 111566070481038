import { esApi } from '@/app/store/api'
import { useSelector } from 'react-redux'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useEffect } from 'react'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_GET_PHONENUMBER_SERVER } from '@/app/module/phonenumbers/definitions'

export const useGetPhoneNumber = (id: number) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const res = esApi.endpoints.getOrgDid.useQuery(
    {
      orgId: orgId.toString(),
      didId: id.toString(),
    },
    { skip: !orgId || !id },
  )

  useEffect(() => {
    if (!res.error) {
      return
    }
    dispatch(
      createNotification({
        'get-phonenumber-failure': {
          type: 'error',
          message: ERROR_GET_PHONENUMBER_SERVER,
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
