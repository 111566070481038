import Checkbox from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { pick } from 'ramda'
import { Component } from 'react'
import SearchBar from '@/app/component/atom/search'
import Tooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import FieldStatic from '@/app/component/layout/field/static'
import IconText from '@/app/component/layout/icon-text'
import TableLayout from '@/app/component/layout/table'
import TableCell from '@/app/component/layout/table/cell'
import TableRow from '@/app/component/layout/table/row'
import TableToolbar from '@/app/component/layout/table/toolbar'
import { styled } from '@mui/material'

export default class CampaignSubscriptionsTable extends Component {
  componentDidUpdate(prevProps) {
    const { selection, onSelect } = this.props
    if (
      selection.isAll !== prevProps.selection.isAll ||
      JSON.stringify(selection.list) !== JSON.stringify(prevProps.selection.list)
    ) {
      onSelect(pick(['isAll', 'list'], selection))
    }
  }

  render() {
    const { loading, query, data, specs, headers, ordered, pagination, search, searchLabel, selection, root } =
      this.props
    return (
      <div style={{ minHeight: '350px' }}>
        <Loading isLoading={loading}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableToolbar>
              {search && (
                <SearchBar
                  namespace="campaign-subscriptions"
                  label={searchLabel}
                  disabled={loading}
                  searchTerm={search.term}
                  onSubmit={(searchTerm) => search.onSubmit(searchTerm)}
                />
              )}
            </TableToolbar>
          </div>
          <TableLayout
            loading={loading}
            pageSize={ordered.length}
            pagination={pagination}
            emptyMessage={
              query.searchTerm ? `No results for "${query.searchTerm}".` : 'No items were found to subscribe.'
            }
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Tooltip title={`${selection.count} ${selection.count === 1 ? 'item' : 'items'} selected`}>
                      <Checkbox
                        disabled={this.props.root === 'groups'}
                        id="campaign-item-subscribe-all"
                        color="primary"
                        checked={selection.all}
                        checkedIcon={<Icon>{selection.headerIcon}</Icon>}
                        onClick={selection.headerHandler}
                      />
                    </Tooltip>
                  </TableCell>
                  {headers.map(({ title }, i) => (
                    <TableCell key={i}>{title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ordered.map((itemId, itemIndex) => {
                  const rowKey = root === 'contacts' ? itemIndex : itemId
                  const isSelected = selection.isSelected(itemId)
                  return (
                    <TableRow className="table-row-item" key={itemId}>
                      <StyledTableCell>
                        <div className="campaign-item-subscribe">
                          <Checkbox
                            color="primary"
                            checked={isSelected}
                            onClick={() => {
                              if (!isSelected) {
                                selection.add([itemId])
                                return
                              }
                              selection.remove([itemId])
                            }}
                          />
                        </div>
                      </StyledTableCell>
                      {headers.map(({ fields, icon }, index) => (
                        <StyledTableCell key={index}>
                          <IconText>
                            {icon && <Icon>{icon}</Icon>}
                            {fields.map((key, i) => {
                              const field = i > 0 ? ` ${data[rowKey][key] || ''}` : data[rowKey][key] || ''

                              return <FieldStatic key={key} name={key} specs={specs[key]} field={field} title={field} />
                            })}
                          </IconText>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableLayout>
        </Loading>
      </div>
    )
  }
}

const StyledTableCell = styled(TableCell)(() => ({
  '& .MuiTypography-root': {
    maxWidth: '30vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
