import { styled } from '@mui/material'

export default function Flag({ code = '' }) {
  return <I className={`fi fi-${code.toLowerCase()}`} title={getCountryName(code)} />
}

const I = styled('i')({
  backgroundColor: '#e2e2e2',
})

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
const getCountryName = (code: string) => {
  try {
    return regionNames.of(code)
  } catch (error) {
    return ''
  }
}
